import React from "react";

function Regelliste() {
  return (<ul className="regelliste">
    <li>Deltagelse i en turnering (minst ett parti spilt) gir 10 poeng.</li>
    <li>Hvert poeng i Bullet-turnering gir 1 poeng.</li>
    <li>Hvert poeng i Lynsjakk- eller Fischersjakkturnering gir 2 poeng.</li>
    <li>Det deles ut 50 ekstrapoeng i hver turnering til de 4 første plassene, fordelt slik: <b>20 – 14 – 10 – 6</b></li>
  </ul>)
}

export default Regelliste;