import medal1 from "./icons/medal.png";
import multitalent from "./icons/multitalent.png";
import second from "./icons/second.png";
import award from "./icons/award.png";
import third from "./icons/third.png";
import leet from "./icons/hacker.png";
import devil from "./icons/devil.png";
import fischer from "./icons/fischer.png";
import bulletbill from "./icons/bulletbill.png";
import shark from "./icons/shark.png";
import pilopp from "./icons/top-right.png";
import ti from "./icons/10.png";
import tjue from "./icons/20.png";
import femti from "./icons/50.png";
import hundre from "./icons/100.png";
import target from "./icons/target.png";
import slingshot from "./icons/slingshot.png";
import boxer from "./icons/boxer.png";
import gm from "./icons/crown.png";
import im from "./icons/quality.png";
import fm from "./icons/radio.png";
import cm from "./icons/cm.png";
import giri from "./icons/giri.png";
import arm from "./icons/robot-arm.png";
import king from "./icons/king.png";
import gepard from './icons/cheetah.png';
import bnils from './icons/bnils.png';
import orangutan from './icons/orangutan.png';
import gg from './icons/gg.png';
import shock from './icons/shock.png';
import langrokade from './icons/langrokade.png';
import kortrokade from './icons/kortrokade.png';
import rogue from './icons/rogue.png';
import fox from './icons/fox.png';
import soze from './icons/soze.png';
import fight from './icons/fight.png';
import promotion from './icons/promotion.png';
import knightmate from './icons/knightmate.png';
import bishopknight from './icons/bishopknight.png';

export default [
  {
    title: "GULL",
    image: medal1,
    description: "Vinn en turnering.",
    secret: false,
    fr_specific: false,
    check: function(player) {
      return (player.firstPlaces);
    }
  },
  {
    title: "SØLV",
    image: second,
    description: "Kom på andreplass.",
    secret: false,
    fr_specific: false,
    check: function(player) {
      return (player.secondPlaces);
    }
  },
  {
    title: "BRONSE",
    image: third,
    description: "Kom på tredjeplass.",
    secret: false,
    fr_specific: false,
    check: function(player) {
      return (player.thirdPlaces);
    }
  },
  {
    title: "MULTITALENT",
    image: multitalent,
    description: "Spill alle 3 turneringstyper.",
    secret: false,
    fr_specific: true,
    check: function(player) {
      return player.tournaments.find(t => t.key === "bullet") && player.tournaments.find(t => t.key === "blitz") && player.tournaments.find(t => t.key === "chess960");
    }
  },
  {
    title: "MESTER",
    image: award,
    description: "Kom på pallen i minst fem turneringer.",
    secret: false,
    fr_specific: false,
    check: function(player) {
      const pallPlasseringer = player.firstPlaces + player.secondPlaces + player.thirdPlaces;
      return pallPlasseringer > 4;
    }
  },
  {
    title: "UNDERDOG",
    image: slingshot,
    description: "Slå en høyere ratet spiller.",
    secret: false,
    fr_specific: false,
    check: function(player) {
      return player.beaten_higher_rated
    }
  },
  {
    title: "LYNHAI",
    image: shark,
    description: "Vinn en Lynsjakkturnering.",
    secret: false,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.tournaments.filter(t => t.key === "blitz" && t.rank === 1).length;
    }
  },
  {
    title: "BULLET BILL",
    image: bulletbill,
    description: "Vinn en Bullet-turnering.",
    secret: false,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.tournaments.find(t => t.key === "bullet" && t.rank === 1);
    }
  },
  {
    title: "FISCHER",
    image: fischer,
    description: "Vinn en Fischersjakkturnering.",
    secret: false,
    fr_specific: true,
    check: function(player, alle_turneringer, cumScores) {
      return player.tournaments.find(t => t.key === "chess960" && t.rank === 1);
    }
  },
  {
    title: "PRESTASJON",
    image: pilopp,
    description: "Prester høyere enn ratingen i en turnering.",
    secret: false,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.tournaments.find(t => t.performance - t.rating > 0);
    }
  },
  {
    title: "TI",
    image: ti,
    description: "Få minst ti poeng i en turnering.",
    secret: false,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.tournaments.find(t => t.score > 9);
    }
  },
  {
    title: "TJUE",
    image: tjue,
    description: "Få minst tjue poeng i en turnering.",
    secret: false,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.tournaments.find(t => t.score > 19);

    }
  },
  {
    title: "FEMTI",
    image: femti,
    description: "Få minst femti poeng i en turnering.",
    secret: false,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.tournaments.find(t => t.score > 49);

    }
  },
  {
    title: "HUNDRE",
    image: hundre,
    description: "Få minst hundre poeng i en turnering.",
    secret: false,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.tournaments.find(t => t.score > 99);
    }
  },
  {
    title: "TITTELKNUSER",
    image: boxer,
    description: "Slå en tittelspiller.",
    secret: false,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.beaten_titled;
    }
  },
  {
    title: "ORANGUTANG",
    image: orangutan,
    description: "Vinn med 1. b4",
    secret: false,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.win_with_b4
    }
  },
  {
    title: "GG",
    image: gg,
    description: "Vinn med 1. g4",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.win_with_g4
    }
  },
  {
    title: "KONGE",
    image: gm,
    description: "Slå en GM.",
    secret: true,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.beaten_gm;
    }
  },
  {
    title: "PRINS",
    image: im,
    description: "Slå en IM.",
    secret: true,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.beaten_im;
    }
  },
  {
    title: "DAB",
    image: fm,
    description: "Slå en FM.",
    secret: true,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.beaten_fm;
    }
  },
  {
    title: "CM-KNUSER",
    image: cm,
    description: "Slå en CM.",
    secret: true,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.beaten_cm;
    }
  },
  {
    title: "WTF",
    image: shock,
    description: "Spill Fischersjakk fra vanlig utgangsstilling.",
    secret: true,
    fr_specific: true,
    check: function(player, alle_turneringer, cumScores) {
      return player.fr_vanlig
    }
  },
  {
    title: "LAAANG ROKADE",
    image: langrokade,
    description: "Utfør den lengste mulige rokade i Fischersjakk.",
    secret: true,
    fr_specific: true,
    check: function(player, alle_turneringer, cumScores) {
      return player.fr_castle_1
    }
  },
  {
    title: "1. 0-0",
    image: kortrokade,
    description: "Utfør rokade i det første trekket i Fischersjakk.",
    secret: true,
    fr_specific: true,
    check: function(player, alle_turneringer, cumScores) {
      return player.fr_castle_0
    }
  },
  {
    title: "LEET",
    image: leet,
    description: "Ha hatt 1337 i sammenlagt turneringsscore.",
    secret: true,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      if (!cumScores) return false;
      return cumScores.includes(1337);
    }
  },
  {
    title: "BEIST",
    image: devil,
    description: "Ha hatt 666 i sammenlagt turneringsscore.",
    secret: true,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      if (!cumScores) return false;
      return cumScores.includes(666);
    }
  },
  {
    title: "33",
    image: target,
    description: "Få nøyaktig 33 poeng i en turnering.",
    secret: true,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.tournaments.find(t => t.score === 33);
    }
  },
  {
    title: "GIRI",
    image: giri,
    description: "Spill remis i alle partiene i en turnering.",
    secret: true,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.drawn_all_games;
    }
  },
  {
    title: "WOW",
    image: arm,
    description: "Slå en spiller som er ratet minst 300 poeng høyere.",
    secret: true,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.beaten_300_higher_rated
    }
  },
  {
    title: "GEPARD",
    image: gepard,
    description: "Spill 40+ partier på én turnering.",
    secret: true,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.flest_partier_paa_en_turnering > 39
    }
  },
  {
    title: "UMULIG",
    image: king,
    description: "Slå DrNykterstein aka Magnus Carlsen.",
    secret: true,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.beaten_magnus
    }
  },
  {
    title: "SPILLER",
    image: rogue,
    description: "Spill 100 partier.",
    secret: true,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.totalt_partier > 99;
    }
  },
  {
    title: "SPILLER",
    image: rogue,
    enabled: false,
    description: "Spill 200 partier.",
    secret: false,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.totalt_partier > 199;
    }
  },
  {
    title: "REV",
    image: fox,
    description: "Spill 300 partier.",
    secret: false,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.totalt_partier > 299;
    }
  },
  {
    title: "KEYSER SÖZE",
    image: soze,
    description: "Slå alle brikkene til motstanderen.",
    secret: false,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.max_antall_brikker_slaatt == 15;
    }
  },
  {
    title: "FIGHT CLUB",
    image: fight,
    description: "Slå en brikke 8 trekk på rad.",
    secret: false,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.max_antall_slag_paa_rad > 7;
    }
  },
  {
    title: "PROMOSJON",
    image: promotion,
    description: "Promotér minst 4 bønder i løpet av ett parti.",
    secret: false,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.max_number_of_promotions > 3;
    }
  },
  {
    title: "KNIGHTMARE",
    image: knightmate,
    description: "Promoter til springer med matt.",
    secret: false,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.wowowK;
    }
  },
  {
    title: "TEAMWORK",
    image: bishopknight,
    description: "Matt med springer og løper.",
    secret: false,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.mate_with_bishop_and_knight;
    }
  },
  {
    title: "SPILLER",
    image: rogue,
    enabled: false,
    description: "Spill 400 partier.",
    secret: false,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.totalt_partier > 399;
    }
  },
  {
    title: "SPILLER",
    image: rogue,
    enabled: false,
    description: "Spill 500 partier.",
    secret: false,
    fr_specific: false,
    check: function(player, alle_turneringer, cumScores) {
      return player.totalt_partier > 499;
    }
  },
  {
    title: "NILSEN",
    image: bnils,
    description: "Kom over IM Joachim Nilsen (JoaBN) i en turnering.",
    secret: false,
    fr_specific: false,
    enabled: true,
    check: function(player, alle_turneringer, cumScores) {
      if (!alle_turneringer) return false;
      return alle_turneringer.find((turnering) => {
        const spillere = turnering.players;
        if (spillere.indexOf(player.username) === -1) return false;

        return (spillere.indexOf(player.username) < spillere.indexOf('JoaBN'));
      });
    }
  }
]