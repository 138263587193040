import React, { useState } from 'react';
import Om from './components/Om';
import Tabell from './components/Tabell'
import Spiller from './components/Spiller';
import Turneringer from './components/turneringer/Turneringer';
import logo from './logo.png';
import {
  HashRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import players from './files/players.json';
import tournaments from './files/tournament_objects.json';

import './App.scss';
import Container from "@material-ui/core/Container";

function App() {
  const [spillere, setSpillere] = useState([]);

  const turneringer = tournaments.sort((a, b) => {
    if (a.date < b.date) return -1;
    if (a.date > b.date) return 1;
    return 0;
  });

  const getStreak = (playerTournaments, allTournaments) => {
    if (playerTournaments.length === allTournaments.length) return allTournaments.length;

    let streak = 0;

    for (let i = 0; i < allTournaments.length; i++) {
      if (!playerTournaments[i]) return streak;
      if (allTournaments[allTournaments.length - i - 1].date !== playerTournaments[i].date) return streak;
      streak = streak + 1;
    }

    return streak;
  };

  const getLongestStreak = (playerTournaments, allTournaments) => {
    // TODO: fiks denne
    if (playerTournaments.length === allTournaments.length) return allTournaments.length;

    let longestStreak = 0;
    let streak = 0;

    for (let i = 0; i < allTournaments.length; i++) {
      if (!playerTournaments[playerTournaments.length - i - 1]) {
        return longestStreak;
      };

      if (allTournaments[allTournaments.length - i - 1].date !== playerTournaments[playerTournaments.length - i - 1].date) {
        if (streak > longestStreak) longestStreak = streak;
        streak = 0;
      } else {
        streak = streak + 1;
        if (streak > longestStreak) longestStreak = streak;
      }
    }

    return longestStreak;
  };

  const playersWithScore = players.map((player) => {

    const bulletPoints = player.tournaments.filter(t => t.key === "bullet").sum("score");
    const blitzPoints = 2 * player.tournaments.filter(t => t.key === "blitz").sum("score");
    const chess960Points = 2 * player.tournaments.filter(t => t.key === "chess960").sum("score");
    const participationPoints = 10 * player.tournaments.length;

    const firstPlaces = player.tournaments.filter(t => t.rank === 1).length;
    const secondPlaces = player.tournaments.filter(t => t.rank === 2).length;
    const thirdPlaces = player.tournaments.filter(t => t.rank === 3).length;
    const fourthPlaces = player.tournaments.filter(t => t.rank === 4).length;

    const rankPoints = firstPlaces * 20 + secondPlaces * 14 + thirdPlaces * 10 + fourthPlaces * 6;

    const fullScore = bulletPoints + blitzPoints + chess960Points + participationPoints + rankPoints;

    let prevBulletPoints = bulletPoints;
    let prevBlitzPoints = blitzPoints;
    let prevChess960Points = chess960Points;
    let prevParticipationPoints = participationPoints;

    let prevFirstPlaces = firstPlaces;
    let prevSecondPlaces = secondPlaces;
    let prevThirdPlaces = thirdPlaces;
    let prevFourthPlaces = fourthPlaces;

    let prevRankPoints = rankPoints;

    let prevFullScore = fullScore;

    if (player.tournaments[player.tournaments.length-1].date === turneringer[turneringer.length-1].date) {
      prevBulletPoints = player.tournaments.slice(0, -1).filter(t => t.key === "bullet").sum("score");
      prevBlitzPoints = 2 * player.tournaments.slice(0, -1).filter(t => t.key === "blitz").sum("score");
      prevChess960Points = 2 * player.tournaments.slice(0, -1).filter(t => t.key === "chess960").sum("score");
      prevParticipationPoints = 10 * player.tournaments.slice(0, -1).length;

      prevFirstPlaces = player.tournaments.slice(0, -1).filter(t => t.rank === 1).length;
      prevSecondPlaces = player.tournaments.slice(0, -1).filter(t => t.rank === 2).length;
      prevThirdPlaces = player.tournaments.slice(0, -1).filter(t => t.rank === 3).length;
      prevFourthPlaces = player.tournaments.slice(0, -1).filter(t => t.rank === 4).length;

      prevRankPoints = prevFirstPlaces * 20 + prevSecondPlaces * 14 + prevThirdPlaces * 10 + prevFourthPlaces * 6;

      prevFullScore = prevBulletPoints + prevBlitzPoints + prevChess960Points + prevParticipationPoints + prevRankPoints;
    }

    const streak = getStreak(player.tournaments, turneringer);
    const longestStreak = getLongestStreak(player.tournaments, turneringer);

    const maxRating = Math.max(...player.tournaments.map(o => o.rating), 0);

    const ratingGroup = maxRating < 1500 ? 'U1500' : maxRating < 2000 ? 'U2000' : '';

    return {...player, streak: streak, fullScore: fullScore, bulletPoints: bulletPoints, blitzPoints: blitzPoints, chess960Points: chess960Points, participationPoints: participationPoints, firstPlaces: firstPlaces, secondPlaces: secondPlaces, thirdPlaces: thirdPlaces, fourthPlaces: fourthPlaces, maxRating: maxRating, ratingGroup: ratingGroup, prevFullScore: prevFullScore}
  });

  if (!spillere.length) {
    setSpillere(playersWithScore);
  }

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <a href="#"><img src={logo} height="20px;"/></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <ul className="nav navbar-nav">
            <li><Link className="nav-item nav-link" to="/">Tabell</Link></li>
            <li><Link className="nav-item nav-link" to="/turneringer">Turneringer</Link></li>
            <li><Link className="nav-item nav-link" to="/om">Om</Link></li>
          </ul>
        </div>
      </nav>
      <div>
        <Container className="main-container" maxWidth="lg">
          <Switch>
            <Route path={process.env.PUBLIC_URL + '/om'}>
              <Om />
            </Route>
            <Route exact path="/">
              <Tabell spillere={spillere} />
            </Route>
            <Route path="/turneringer">
              <Turneringer tournaments={tournaments} />
            </Route>
            <Route path="/spiller/:brukernavn" component={props => <Spiller brukernavn={props.match.params.brukernavn} spillere={spillere} alle_turneringer={turneringer} />}/>
          </Switch>
        </Container>
      </div>
      <footer id="sticky-footer" className="py-4 bg-light footer">
        <a href="https://facebook.com/offerspillsk" target="_blank" rel="noopener noreferrer" className="fa fa-facebook" style={{fontSize: "32px", color: "#3b5998"}}></a>
        <a href="https://twitter.com/offerspill" target="_blank" rel="noopener noreferrer" className="fa fa-twitter" style={{fontSize: "32px", color: "#1DA1F2"}}></a>
        <a href="https://github.com/offerspill" target="_blank" rel="noopener noreferrer" className="fa fa-github" style={{fontSize: "32px", color: "#000"}}></a>
        <a href="mailto:league@offerspill.no" target="_blank" rel="noopener noreferrer" className="fa fa-envelope-square" style={{fontSize: "32px", color: "#555"}}></a>
        <div className="ikoner">Ikoner lagd av <a href="https://www.flaticon.com/authors/freepik" target="_blank" rel="noopener noreferrer" title="Freepik">Freepik</a> fra <a href="https://www.flaticon.com/" target="_blank" rel="noopener noreferrer" title="Flaticon">www.flaticon.com</a></div>      </footer>
    </Router>

  );
}

export default App;
