import React from 'react';
import Regelliste from './Regelliste';

import '../App.scss';

function Om() {
  return (
    <div className="om">
        <h1>Offerspill Chess League</h1>
        <p>Velkommen til Offerspill Chess League.</p>
        <p>Vi spiller ukentlige turneringer på Lichess.org, der medlemmer kan ta poeng og vinne fete premier!</p>
      <h3>Hvordan delta?</h3>
      <p>For å delta i Offerspills turneringer på nett, må du være medlem av Offerspill Sjakklubb og gruppen vår på Lichess.org. Du melder deg inn i klubben ved å fylle ut <a href="https://innmelding.offerspill.com/" target="_blank" rel="noopener noreferrer">dette skjemaet</a>.</p>
      <p>Når du er medlem av Offerspill Sjakklubb, kan du bli med i Lichess-gruppen vår. Den finner du <a href="https://lichess.org/team/offerspill-sjakklubb">her</a>. Trykk <b>JOIN TEAM</b> og skriv inn <b>ditt fulle navn</b> i meldingsboksen som dukker opp. Det er viktig at du skriver inn navnet ditt i denne boksen, sånn at vi kan verifisere at du er medlem.</p>
      <p>Når du har blitt medlem av gruppen på Lichess kan du bli med på turneringene vi arrangerer der. Du finner liste over kommende turneringer både i gruppen på Lichess og på <a href="https://offerspill.com">offerspill.com</a>. Vi henter automatisk resultater fra Lichess-turneringene og oppdaterer poengene i ligaen.</p>
      <h3>Premier</h3>
      <p>Det vil bli gitt premie til de 3 spillerne med høyest totale poengsum på slutten av en sesong. Styremedlemmer kan ikke vinne; dersom det skulle skje går premien til neste på listen. Det vil også bli gitt premier i ratingklassene <b>U2000</b> og <b>U1500</b>. Det er den maksimalt oppnådde ratingen i løpet av alle turneringene som brukes for å bestemme hvilken ratingklasse man spiller i. Dette gjelder uavhengig av variant eller tidskontroll, så dersom man har en Lynsjakkrating på 1300, Bulletrating på 1100 og Fischersjakkrating på 1500, kan man vinne i klassen U2000, men ikke U1500. Det vil også bli gitt sporadiske ekstrapremier til deltagere, f.eks. etter å ha spilt et pent parti eller å ha prestert bra i forhold til egen rating. Vi kan røpe av premiene inkluderer sjakkbrett signert av Magnus Carlsen!</p>
      <h3>Poengregler</h3>
      <Regelliste/>
    </div>
  );
}

export default Om;
