import React, { useState, useEffect } from 'react';
import Regelliste from './Regelliste';
import icon_up from '../icon_up.png';
import icon_down from '../icon_down.png';
import ReactTooltip from 'react-tooltip';
import moment from "moment";
import ndjsonStream from 'can-ndjson-stream';
import 'moment/locale/nb'
import {
  Link
} from "react-router-dom";

moment.locale('nb');

function Tabell({spillere}) {

  const [ratinggruppe, setRatinggruppe] = useState("alle");
  const [nesteTurneringer, setNesteTurneringer] = useState([]);

  spillere.sort((a, b) => b.prevFullScore - a.prevFullScore);

  spillere.forEach((el, index) => {
    el.prevFullScoreRank = index + 1;
  });

  spillere.sort((a, b) => b.fullScore - a.fullScore);

  spillere.forEach((el, index) => {
    el.fullScoreRank = index + 1;

    if (el.fullScoreRank === el.prevFullScoreRank || el.tournaments.length < 2) {
      el.trend = "sideways"
    } else if (el.fullScoreRank < el.prevFullScoreRank) {
      el.trend = "up";
    } else if (el.fullScoreRank > el.prevFullScoreRank) {
      el.trend = "down";
    }
  });

  let filtrerteSpillere = spillere;

  if (ratinggruppe !== "alle") {
    filtrerteSpillere = spillere.filter(spiller => spiller.ratingGroup === ratinggruppe);
  }

  const handleSelectChange = (event) => {
    switch(event.target.value) {
      case "alle":
        setRatinggruppe("alle");
        break;
      case "U2000":
        setRatinggruppe("U2000");
        break;
      case "U1500":
        setRatinggruppe("U1500");
    }
  };

  useEffect(() => {
    async function fetchData() {
      fetch("https://lichess.org/api/user/offerspillsk/tournament/created?nb=10")
      .then((response) => {
        return ndjsonStream(response.body)
      })
      .then((exampleStream) => {
        let read;
        let tournaments = []
        const reader = exampleStream.getReader();

          reader.read().then(function processValue({ done, value }) {
          // Result objects contain two properties:
          // done  - true if the stream has already given you all its data.
          // value - some data. Always undefined when done is true.
          if (done) {
            console.log("Fetched tournaments");
            return tournaments;
          }

          tournaments.push(value);
      
          // Read some more, and call this function again
          return reader.read().then(processValue);
        }).then((data) => {
          setNesteTurneringer(tournaments);
        });
      })
    }

    fetchData();
    window.scrollTo(0, 0);
  }, [])

  nesteTurneringer.sort((a, b) => a.startsAt - b.startsAt);
  
  const t = Math.round((new Date()).getTime());

  const currentTurnering = nesteTurneringer.find(turnering => t < turnering.finishesAt && t > turnering.startsAt);
  const kommendeTurneringer = nesteTurneringer.filter(turnering => t < turnering.startsAt);

  return (
    <>
      <ReactTooltip />
      <h2>Offerspill Chess League</h2>
      <br/>
      {currentTurnering ? <div className="live-turneringer"><div className="glowing-circle"></div><h3 className="current-turnering">Nå spilles <a href={"https://lichess.org/tournament/" + currentTurnering.id} target="_blank" rel="noopener noreferrer">{currentTurnering.fullName}</a></h3></div> : null}
      {kommendeTurneringer.length ? <div className="kommende-turneringer">
        {kommendeTurneringer.length === 1 ? <h4>Neste turnering</h4> : <h4>Neste {kommendeTurneringer.length} turneringer</h4>}
        {kommendeTurneringer.map(turnering => (
          <div>{moment(turnering.startsAt).format('Do MMMM HH:mm')}: <a href={"https://lichess.org/tournament/" + turnering.id} target="_blank" rel="noopener noreferrer">{turnering.fullName}</a></div>
        ))}
      </div> : null}
        <p>Slik regnes poengene ut:
          <Regelliste/>
          For å se detaljerte poeng for en spiller, kan du trykke på spillerens navn.</p>
        <div className="col-md-4 wrap-select" style={{padding: 0}}>
        <p className="sorter-tekst">Sorter etter ratinggruppe:</p>
        <select className="velg-ratinggruppe form-control mb-2 mr-sm-2" onChange={handleSelectChange}>
          <option value="alle">Alle</option>
          <option value="U2000">U2000</option>
          <option value="U1500">U1500</option>
        </select>
      </div>
        <table id="scoreboard" className="scoreboard table striped table-responsive-md">
          <thead>
          <tr>
            <th style={{textAlign: "center"}}>#</th>
            <th style={{textAlign: "left"}}>Brukernavn</th>
            <th style={{textAlign: "right"}}>Poeng</th>
            <th style={{textAlign: "right"}}>Antall turneringer spilt</th>
            <th style={{textAlign: "right"}}>Høyeste turneringsplassering</th>
          </tr>
          </thead>
          <tbody>
          {filtrerteSpillere.map((player, i) => {
            const min_rank = Math.min(...player.tournaments.map(o => o.rank), 10000);
            const num_tournaments = player.tournaments.length;

            const oppNed = player.fullScoreRank - player.prevFullScoreRank > 0 ? "ned" : "opp";
            const plasser = Math.abs(player.fullScoreRank - player.prevFullScoreRank) > 1 ? "plasser" : "plass";

            const rankTooltip = player.fullScoreRank !== player.prevFullScoreRank ? player.username + " har gått " + oppNed + " " + Math.abs(player.fullScoreRank - player.prevFullScoreRank) + " " + plasser + " etter siste turnering." : "";

            return (<tr>
              <td>
                <div className="rank-wrapper"><span style={{display: "inline-block", textAlign: "left"}}><b>{ratinggruppe === "alle" ? player.fullScoreRank : `${(i + 1)} (${player.fullScoreRank} totalt)`}</b></span></div>
              </td>
              <td style={{textAlign:"left"}}>
                <Link to={"/spiller/" + player.username}>{player.username}</Link> <span className="rating" title="Høyeste rating som er observert"><i>{player.maxRating}</i></span>
              </td>
              <td style={{textAlign:"right"}}>
                {player.fullScore}
              </td>
              <td style={{textAlign:"right"}}>
                {num_tournaments}
              </td>
              <td style={{textAlign:"right"}}>
                {min_rank}
              </td>
            </tr>)
          })}
          </tbody>
        </table>
      </>
  );
}

export default Tabell;
