import React from 'react';
import moment from "moment";
import '../../App.scss';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import 'moment/locale/nb';
import Correlation from 'correlation-rank';
moment.locale('nb');

function Turneringer({ tournaments }) {
  const sortedTournaments = tournaments.sort((a, b) => {
    if (a.date > b.date) return 1;
    if (a.date < b.date) return -1;
    return 0;
  });

  const blitz = sortedTournaments.filter(t => t.key === 'blitz').length;
  const chess960 = sortedTournaments.filter(t => t.key === 'chess960').length;
  const bullet = sortedTournaments.filter(t => t.key === 'bullet').length;

  const totalPlayers = sortedTournaments.reduce((acc, tournament) => acc + tournament.players.length, 0);
  const averagePlayers = parseInt(totalPlayers / sortedTournaments.length, 10);

  const dates = sortedTournaments.map(tournament => moment(tournament.date, 'YYYY.MM.DD').format('Do MMMM YYYY'));


  const weather = [4, 13, 12, 18, 21, 15, 8, 11, 16, 13, 19, 17, 13, 13, 11, 16, 17, 16, 22, 21, 24, 27, 23, 13, 16, 27, 29, 29, 30, 19, 28, 31, 21, 19, 20, 18, 20, 23, 21, 23, 18, 24, 21, 22, 21, 21]

  const numberOfPlayers = sortedTournaments.map(tournament => tournament.players.length);

  const weather_corr = Correlation.rank(numberOfPlayers, weather);

  const numPlayers = [];

  for (let i = 0; i < sortedTournaments.length; i++) {
    const magnusPlayed = sortedTournaments[i].players.find(player => player === "DrNykterstein");
    let pointColor = "#7CB5EC";

    numPlayers.push({y: sortedTournaments[i].players.length, color: pointColor});
  }

  const options = {
    chart: {
      spacingTop: 20,
      backgroundColor: '#272521',
    },
    title: {
      text: 'Antall spillere i turneringene',
      style: {
        color: '#BABABA'
      }
    },
    tooltip: {
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}:</td>' +
        '<td style="padding:0"><b>{point.y}<br></b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    xAxis: {
      categories: dates
    },
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: {
        color: '#BABABA'
      }
    },
    series: [
      {
        name: 'Antall spillere',
        data: numPlayers,
      },
      {
        visible: false,
        name: 'Været',
        data: weather,
        color: "#FFF",
        tooltip: {
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}:</td>' +
            '<td style="padding:0"><b>{point.y} grader<br></b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
      },
    ]
  };

  return (
    <div>
      <h2>Turneringer</h2>
      <p>Det er totalt spilt {sortedTournaments.length} turneringer: {blitz} lyn og {bullet} bullet.</p>
      <p>Gjennomsnittlig antall spillere: {averagePlayers}.</p>
      {weather_corr !== 1 && <p>Korrelasjon mellom antall spillere og antall grader i Oslo: {weather_corr.toFixed(2)}.</p>}
      <div className="antall-spillere-chart">
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      </div>
        <table id="scoreboard" className="scoreboard table striped table-responsive-md">
        <thead>
          <tr>
            <th style={{textAlign: "center"}}>Dato</th>
            <th style={{textAlign: "left"}}>Navn</th>
            <th style={{textAlign: "center"}}>Antall spillere</th>
            <th style={{textAlign: "left"}}>Vinner</th>
          </tr>
          </thead>
          {sortedTournaments.map(tournament => {
            return (
              <tr>
                <td>{moment(tournament.date, 'YYYY.MM.DD').format('Do MMMM YYYY')}</td>
                <td style={{textAlign: "left"}}><a href={tournament.url} target="_blank" rel="noopener noreferrer">{tournament.fullName}</a></td>
                <td style={{textAlign: "center"}}>{tournament.players.length}</td>
                <td style={{textAlign: "left"}}>{tournament.players[0]}</td>
              </tr>
            ) 
          })}
        </table>
    </div>
  );
}

export default Turneringer;
