import React from 'react';

import '../App.scss';
import achievements from '../achievements';

function Achievements({player, alle_turneringer, cumScores}) {
  const oppnadde = achievements.filter(achievement => achievement.check(player)).length;
  const oppnaddeTekst = oppnadde === 1 ? "achievement" : "achievements";

  const DISABLE_FR_ACHIEVEMENTS = true;

  return (
    <>
      <p>{player.username} har oppnådd {oppnadde} {oppnaddeTekst}.</p>
      <div className="achievement-container">
        {achievements.map((achievement) => {
          if ((achievement.enabled === false) || (DISABLE_FR_ACHIEVEMENTS && achievement.fr_specific)) return null;

          const achieved = achievement.check(player, alle_turneringer, cumScores);
          const hemmelig = achievement.secret && (!achieved);

          const titleClass = achieved ? "achievement-title achieved" : "achievement-title";
          return (
              <div key={achievement.title} className="achievement-element">
                <p className={titleClass}>{!hemmelig ? achievement.title : "?"}</p>
                <img src={achievement.image} className={achieved ? "jepp": "nope"} height="64px"></img>
                <p className="achievement-description">{!hemmelig ? achievement.description : "Hemmelig til den er oppnådd ;)"}</p>
              </div>)
          })
        }
      </div>
    </>
  );
}

export default Achievements;
