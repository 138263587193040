import React, {useState, useEffect} from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from "moment";
import '../App.scss';
import 'moment/locale/nb'
import ReactTooltip from 'react-tooltip';
import streak2 from '../icons/streak2.png';
import streak5 from '../icons/streak5.png';
import streak10 from '../icons/streak10.png';
import lyn from '../icons/lyn.png';
import dice from '../icons/dice.png';
import bullet from '../icons/bullet.png';
import scoring from '../icons/scoring.png';
import deltager from '../icons/deltager.png';
import sjakkbrett from '../icons/chessboard.png';
import mouse from '../icons/cursor.png';

import Achievements from "./Achievements";
moment.locale('nb');

function Spiller({ brukernavn, spillere, alle_turneringer }) {
  const [detaljertTekst, setDetaljertTekst] = useState("Detaljert poenginformasjon");
  const [lichessSpillerState, setLichessSpillerState] = useState({});
  const player = spillere.find(player => player.username === brukernavn);


  useEffect(() => {
    async function fetchData() {
      const res = await fetch("https://lichess.org/api/user/" + player.username);
      res
        .json()
        .then(res => setLichessSpillerState(res))
        .catch(() => {console.log("Kunne ikke hente spiller")})  
    }

    fetchData();

    window.scrollTo(0, 0);
  }, []);

  const toggleTekst = () => {
    if (detaljertTekst === "Detaljert poenginformasjon") {
      setDetaljertTekst("Lukk");
    } else {
      setDetaljertTekst("Detaljert poenginformasjon")
    }
  };

  if (!player) {
    return <h1>{brukernavn} har ikke spilt noen Offerspill-turneringer.</h1>
  }

  const highestGap = Math.max(...player.tournaments.map(t => t.performance - t.rating));

  const cumScores = [];
  let sum = 0;

  let scores = [];

  for (let i = 0; i < alle_turneringer.length; i++) {
    const spiltTurnering = player.tournaments.find(obj => obj.date === alle_turneringer[i].date);
    let currentScore = 0;
    let pointColor = "#E6ADA3";

    if (spiltTurnering) {
      currentScore = spiltTurnering.score;
      sum += spiltTurnering.score;
    } else {
      pointColor = "#FFF"
    }

    cumScores.push({y: sum, color: pointColor});
    scores.push(currentScore);
  }

  const labels = alle_turneringer.map((t) => {
    let variant = '';

    if (t.key === 'chess960') variant = 'Fischersjakk';
    if (t.key === 'blitz') variant = 'Lynsjakk';
    if (t.key === 'bullet') variant = 'Bullet';

    return variant + ' ' + t.date;
  });

  const options = {
    chart: {
      spacingTop: 20,
      backgroundColor: '#272521',
    },
    title: {
      text: 'Turneringspoeng',
      style: {
        color: '#BABABA'
      }
    },
    subtitle: {
      text: 'Turneringer man ikke har deltatt i er markert som hvite punkter.',
      style: {
        color: '#BABABA'
      }
    },
    tooltip: {
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}:</td>' +
        '<td style="padding:0"><b>{point.y}<br></b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    xAxis: {
      categories: labels
    },
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: {
        color: '#BABABA'
      }
    },
    series: [
      {
        name: 'Poeng',
        data: scores,
        type: 'column',
        color: '#5D7BDE'
      },
    {
      name: 'Summert',
      data: cumScores,
      color: '#E6ADA3'
    }]
  };

  const getStreakIcon = (streak) => {
    if (streak > 9) return streak10;
    if (streak > 4) return streak5;
    if (streak > 1) return streak2;

    return ""
  };

  const streakIcon = getStreakIcon(player.streak);
  const fornavn = lichessSpillerState.profile && lichessSpillerState.profile.firstName ? lichessSpillerState.profile.firstName : "";
  const etternavn = lichessSpillerState.profile && lichessSpillerState.profile.lastName ? lichessSpillerState.profile.lastName : "";
  const navn = fornavn + " " + etternavn;
  const tittel = lichessSpillerState.title ? lichessSpillerState.title : "";

  const streakClass = player.streak > 19 ? "streakIcon streak-20" : "streakIcon";

  return (
    <div>
      <ReactTooltip/>
      <h2 data-tip={`${lichessSpillerState.online ? "Pålogget på Lichess" : ""}`} className={`player-username ${lichessSpillerState.online ? "online" : ""}`}>{tittel} {player.username}</h2><div className="navn">{navn ? navn : null}</div>
      <div className="perfs">
      {player.streak > 1 ? <div data-tip="Streak" className="streak perfchild"><h4>{player.streak}</h4> <img src={streakIcon} className={streakClass} height="28px" /></div> : null}
      <div data-tip="Totalpoeng" className="total perfchild"><h4>{player.fullScore}</h4> <img src={scoring} height="28px"/></div>
      <div data-tip="Lynpoeng" className="lyn perfchild"><h4>{player.blitzPoints}</h4> <img src={lyn} height="28px"/></div>
      <div data-tip="Fischersjakkpoeng" className="fischersjakk perfchild"><h4>{player.chess960Points}</h4> <img src={dice} height="28px" /></div>
      <div data-tip="Bulletpoeng" className="bullet perfchild"><h4>{player.bulletPoints}</h4> <img src={bullet} height="28px" /></div>
      <div data-tip="Deltagerpoeng" className="deltager perfchild"><h4>{player.participationPoints}</h4> <img src={deltager} height="28px" /></div>
      <div data-tip="Antall partier spilt" className="partier perfchild"><h4>{player.totalt_partier}</h4><img src={sjakkbrett} height="28px" /></div>
      <div data-tip="Antall trekk" className="deltager perfchild"><h4>{player.antall_trekk}</h4><img src={mouse} height="28px" /></div>
      </div>
      <p>
        <a onClick={() => toggleTekst()} className="btn btn-secondary" data-toggle="collapse" href="#detaljertePoeng" role="button"
           aria-expanded="false" aria-controls="collapseExample">
          {detaljertTekst}
        </a>
      </p>
      <div className="collapse" id="detaljertePoeng">
        <div className="card card-body">
          <div className="poenginfo">
            {player.bulletPoints ? <div>Bullet: {player.bulletPoints}</div> : null}
            {player.blitzPoints ? <div>Lynsjakk: {player.blitzPoints / 2} * 2 = {player.blitzPoints}</div> : null}
            {player.chess960Points ? <div>Fischersjakk: {player.chess960Points / 2} * 2 = {player.chess960Points}</div> : null}
            <div>Deltagerpoeng: {player.tournaments.length} {player.tournaments.length > 1 ? <span>turneringer</span> : <span>turnering</span>} * 10 = {player.participationPoints}</div>
            {player.firstPlaces ? <div>{player.firstPlaces} {player.firstPlaces > 1 ? <span>førsteplasser</span> : <span>førsteplass</span>} gir {player.firstPlaces * 20} poeng</div> : null}
            {player.secondPlaces ? <div>{player.secondPlaces} {player.secondPlaces > 1 ? <span>andreplasser</span> : <span>andreplass</span>} gir {player.secondPlaces * 14} poeng</div> : null}
            {player.thirdPlaces ? <div>{player.thirdPlaces} {player.thirdPlaces > 1 ? <span>tredjeplasser</span> : <span>tredjeplass</span>} gir {player.thirdPlaces * 10} poeng</div> : null}
            {player.fourthPlaces ? <div>{player.fourthPlaces} {player.fourthPlaces > 1 ? <span>fjerdeplasser</span> : <span>fjerdeplass</span>} gir {player.fourthPlaces * 6} poeng</div> : null}
            <div><b>Totalt: {player.fullScore}</b></div>
          </div>
        </div>
      </div>
      <div className="achievements">
        <h4>Achievements</h4>
        <Achievements player={player} alle_turneringer={alle_turneringer} cumScores={cumScores.map(score => score.y)} />
      </div>
      <div className="turneringspoeng-chart">
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      </div>
      <div className="featured-games hide-mobile">
      {player.hoyest_rating_slaatt_parti ?
      <div>
        <h4>Seier mot beste motstander</h4>
        <p><iframe src={"https://lichess.org/embed/" + player.hoyest_rating_slaatt_parti.split('/')[3] + "?theme=auto&bg=dark"}
                   width="600" height="397" frameborder="0"></iframe>
      </p>
      </div> : null}
      {player.max_antall_slag_paa_rad_parti ?
      <div>
        <h4>Flest slag på rad ({player.max_antall_slag_paa_rad})</h4>
        <p><iframe src={"https://lichess.org/embed/" + player.max_antall_slag_paa_rad_parti.split('/')[3] + "?theme=auto&bg=dark"}
                   width="600" height="397" frameborder="0"></iframe>
      </p>
      </div> : null}
      {player.max_number_of_promotion_parti ?
      <div>
        <h4>Flest forvandlinger ({player.max_number_of_promotions})</h4>
        <p><iframe src={"https://lichess.org/embed/" + player.max_number_of_promotion_parti.split('/')[3] + "?theme=auto&bg=dark"}
                   width="600" height="397" frameborder="0"></iframe>
      </p>
      </div> : null}
      {player.max_antall_brikker_slaatt_parti ?
      <div>
        <h4>Flest brikker slått ({player.max_antall_brikker_slaatt})</h4>
        <p><iframe src={"https://lichess.org/embed/" + player.max_antall_brikker_slaatt_parti.split('/')[3] + "?theme=auto&bg=dark"}
                   width="600" height="397" frameborder="0"></iframe>
      </p>
      </div> : null}
      </div>
      <div className="margin-top-2">
        <h4>{brukernavn} har spilt {player.tournaments.length} av {alle_turneringer.length} turneringer:</h4>
        <ul>
          {player.tournaments.sort((a, b) => {
            if (a.date > b.date) return -1;
            if (a.date < b.date) return 1;
            return 0;
          }).map(tournament => <li key={tournament.date} className="spiller-turnering">{moment(tournament.date, 'YYYY.MM.DD').format('Do MMMM YYYY')}: <a href={tournament.url} target="_blank" rel="noopener noreferrer">{tournament.fullName}</a> – {tournament.rank}. plass med {tournament.score} poeng.</li>)}
        </ul>
      </div>
    </div>
  );
}

export default Spiller;
